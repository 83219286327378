const constructQueryPath = (path: string, params: URLSearchParams) => {
  const queryString = params.toString()
  return `${path}${queryString.length > 0 ? '?' : ''}${queryString}`
}

export const directoryPath = (slug: string) => {
  return `/${slug}`
}

export const faqPath = (slug: string) => {
  return `/${slug}/faq`
}

export const landingPath = ({
  partner,
  program,
  path,
}: {
  partner: string
  program: string
  path?: string | null
}) => {
  const params = new URLSearchParams()
  if (path) {
    params.append('path', path)
  }
  return constructQueryPath(`/${partner}/${program}`, params)
}

export const nowPath = ({
  partner,
  program,
  path,
  signUpToken,
}: {
  partner: string
  program: string
  path?: string | null
  signUpToken?: string | null
}) => {
  const params = new URLSearchParams()
  if (path) {
    params.append('path', path)
  }
  if (signUpToken) {
    params.append('signUpToken', signUpToken)
  }
  return constructQueryPath(`/${partner}/now/${program}`, params)
}

export const formPath = ({
  partner,
  program,
  path,
  signUpToken,
}: {
  partner: string
  program: string
  path?: string | null
  signUpToken: string
}) => {
  const params = new URLSearchParams()
  params.append('signUpToken', signUpToken)
  if (path) {
    params.append('path', path)
  }

  return constructQueryPath(`/${partner}/${program}/form`, params)
}

export const confirmationPath = ({
  partner,
  program,
  signUpToken,
  path,
  displayDevices,
}: {
  partner: string
  program: string
  signUpToken: string
  path?: string | null
  displayDevices?: boolean
}) => {
  const params = new URLSearchParams()
  params.append('signUpToken', signUpToken)
  if (path) {
    params.append('path', path)
  }
  if (displayDevices) {
    params.append('displayDevices', '1')
  }

  return constructQueryPath(`/${partner}/${program}/confirmation`, params)
}
