import React from 'react'
import { Link, PageProps } from 'gatsby'
import { DUMMY_TOKEN } from 'hooks/use-signup-object'

import {
  confirmationPath,
  directoryPath,
  faqPath,
  formPath,
  landingPath,
  nowPath,
} from 'lib/paths'

type HomePage = {
  contentful_id: string
}

type SlugPage = {
  contentful_id: string
  slug: string
}

type PartnerProgramPage = {
  contentful_id: string
  partnerSlug: string
  programSlug: string
}

type PageLists = {
  homepage?: HomePage[]
  directory?: SlugPage[]
  faq?: SlugPage[]
  landing?: PartnerProgramPage[]
  now?: PartnerProgramPage[]
  form?: PartnerProgramPage[]
  confirmation?: PartnerProgramPage[]
}

type PageContextProps = {
  pageLists: PageLists
}

type SlugGroup = {
  [slug: string]: {
    directory?: SlugPage
    faq?: SlugPage
  }
}

type PartnerProgramGroup = {
  [key: string]: {
    partner: string
    program: string
    landing?: PartnerProgramPage
    now?: PartnerProgramPage
    form?: PartnerProgramPage
    confirmation?: PartnerProgramPage
  }
}

const groupBySlug = (pageLists: PageLists) => {
  const grouped: SlugGroup = {}
  pageLists.directory?.forEach((page) => {
    grouped[page.slug] ||= {}
    grouped[page.slug].directory = page
  })
  pageLists.faq?.forEach((page) => {
    grouped[page.slug] ||= {}
    grouped[page.slug].faq = page
  })
  return grouped
}

const groupByPartnerProgram = (pageLists: PageLists) => {
  const keyFor = (page: PartnerProgramPage) => {
    return `${page.partnerSlug} / ${page.programSlug}`
  }
  const initHash = (page: PartnerProgramPage) => {
    return { partner: page.partnerSlug, program: page.programSlug }
  }
  const grouped: PartnerProgramGroup = {}
  pageLists.landing?.forEach((page) => {
    const key = keyFor(page)
    grouped[key] ||= initHash(page)
    grouped[key].landing = page
  })
  pageLists.now?.forEach((page) => {
    const key = keyFor(page)
    grouped[key] ||= initHash(page)
    grouped[key].now = page
  })
  pageLists.form?.forEach((page) => {
    const key = keyFor(page)
    grouped[key] ||= initHash(page)
    grouped[key].form = page
  })
  pageLists.confirmation?.forEach((page) => {
    const key = keyFor(page)
    grouped[key] ||= initHash(page)
    grouped[key].confirmation = page
  })
  return grouped
}

const PageDirectoryPage: React.FC<PageProps<unknown, PageContextProps>> = (
  props
) => {
  const { pageLists } = props.pageContext
  const slugGroups = groupBySlug(pageLists)
  const partnerProgramGroups = groupByPartnerProgram(pageLists)

  return (
    <div className="container">
      <h1>Page Directory</h1>
      <div style={{ display: 'flex' }}>
        <div>
          {pageLists.homepage && (
            <>
              <h2>
                <Link to="/">Homepage</Link>
              </h2>
            </>
          )}
          <h2>Slug Pages</h2>
          {Object.keys(slugGroups)
            .sort()
            .map((slug) => (
              <div key={slug}>
                <h3>{slug}</h3>
                <ul>
                  {slugGroups[slug].directory && (
                    <li>
                      <Link to={directoryPath(slug)}>Directory</Link>
                    </li>
                  )}
                  {slugGroups[slug].faq && (
                    <li>
                      <Link to={faqPath(slug)}>FAQ</Link>
                    </li>
                  )}
                </ul>
              </div>
            ))}
        </div>
        <div>
          <h2>Partner / Program Pages</h2>
          {Object.keys(partnerProgramGroups)
            .sort()
            .map((key) => (
              <div key={key}>
                <h3>{key}</h3>
                <ul>
                  {partnerProgramGroups[key].landing && (
                    <li>
                      <Link
                        to={landingPath({
                          partner: partnerProgramGroups[key].partner,
                          program: partnerProgramGroups[key].program,
                        })}
                      >
                        Landing
                      </Link>
                    </li>
                  )}
                  {partnerProgramGroups[key].now && (
                    <li>
                      <Link
                        to={nowPath({
                          partner: partnerProgramGroups[key].partner,
                          program: partnerProgramGroups[key].program,
                        })}
                      >
                        Now
                      </Link>
                    </li>
                  )}
                  {partnerProgramGroups[key].form && (
                    <li>
                      <Link
                        to={formPath({
                          partner: partnerProgramGroups[key].partner,
                          program: partnerProgramGroups[key].program,
                          signUpToken: DUMMY_TOKEN,
                        })}
                      >
                        Form
                      </Link>
                    </li>
                  )}
                  {partnerProgramGroups[key].confirmation && (
                    <li>
                      <Link
                        to={confirmationPath({
                          partner: partnerProgramGroups[key].partner,
                          program: partnerProgramGroups[key].program,
                          signUpToken: DUMMY_TOKEN,
                          displayDevices: true,
                        })}
                      >
                        Confirmation
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default PageDirectoryPage
